import * as React from 'react'
import isEmpty from 'lodash/fp/isEmpty'

import Link from '@/components/common/link'
import AssetImage from '@/components/shape/asset-image'
import type { SearchCompanyFieldsFragment } from '@/gql/generated-api1'
import useLanguage from '@/utils/language/language-hook'
import cryptoDetailPagePath from '@/service/crypto/detail-page-path'

import StyledAssetSearchResult from './asset-search-result-style'

export type OnClick = (props: SearchCompanyFieldsFragment) => void

type Props = SearchCompanyFieldsFragment & {
  onClick?: OnClick
  title?: string // 기타 자산 같은 경우 title을 ticker 대신 노출
  disabled?: boolean
}

const AssetSearchResult = ({ onClick, title, disabled = false, ...searchProps }: Props) => {
  const { t, language: locale } = useLanguage('common')
  const { name, imageUrl, ticker, country, categoryType } = searchProps
  const handleClick = React.useCallback(() => {
    // FIXME: categoryType 없을 경우 에러 처리?
    onClick && onClick(searchProps)
  }, [onClick, searchProps])
  const Container = React.useCallback(
    ({ children }: { children: JSX.Element[] }) => {
      return onClick ? (
        <button onClick={handleClick} disabled={disabled}>
          {children}
        </button>
      ) : (
        <Link
          href={
            categoryType === 'CRYPTO_CURRENCY'
              ? cryptoDetailPagePath({ base: ticker as string, locale })
              : `/stock?ticker=${ticker}&country=${country}`
          }
          gaCategory="HEADER"
          gaAction="HEADER_SEARCH-ASSET_CLICK"
          gaLabel={`HEADER_SEARCH-ASSET_${(country ? country + '-' : '') + ticker}`}>
          {children}
        </Link>
      )
    },
    [categoryType, country, disabled, handleClick, locale, onClick, ticker],
  )

  return (
    <StyledAssetSearchResult>
      <Container>
        <span className="asset-image">
          {imageUrl && <AssetImage imageUrl={imageUrl} alt={ticker || ''} />}
        </span>
        <div className="--flex-column mark">
          <div className="name">
            <div className="--truncate">{name}</div>
          </div>
          <div className="ticker">
            <div className="--text-low-level --truncate">{title || ticker}</div>
          </div>
        </div>
        <div className="--text-low-level country">
          {t(`search.country.${country || categoryType}`, '')}
        </div>
      </Container>
    </StyledAssetSearchResult>
  )
}

export default AssetSearchResult
